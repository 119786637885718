import { useEffect, useState } from 'react'
import axios from "axios"
import styled from 'styled-components'
import { useTranslation } from 'contexts/Localization'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { DEFAULT_META, getCustomMeta } from 'config/constants/meta'
import { useCakeBusdPrice } from 'hooks/useBUSDPrice'
import Container from './Container'

const StyledPage = styled(Container)`
  min-height: calc(100vh - 64px);
  padding-top: 16px;
  padding-bottom: 16px;

  ${({ theme }) => theme.mediaQueries.sm} {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    padding-top: 32px;
    padding-bottom: 32px;
  }
`


export const PageMeta: React.FC<{ symbol?: string }> = ({ symbol }) => {
  const {
    t,
    currentLanguage: { locale },
  } = useTranslation()

  const [cakePrice, setCakePrice] = useState(0)  
  useEffect(() => {
      fetchDefcPrice()
  }, [])

  const fetchDefcPrice = () => {
    axios.get("https://api.coingecko.com/api/v3/coins/defi-coin")
      .then((res) => {
        setCakePrice(res.data.market_data.current_price.usd)
      })
  }

  const { pathname } = useRouter()
  const cakePriceUsd = useCakeBusdPrice()
  const cakePriceUsdDisplay = `$ ${cakePrice}`

  const pageMeta = getCustomMeta(pathname, t, locale) || {}
  const { title, description, image } = { ...DEFAULT_META, ...pageMeta }
  let pageTitle = cakePriceUsdDisplay ? [title, cakePriceUsdDisplay].join(' - ') : title
  if (symbol) {
    pageTitle = [symbol, title].join(' - ')
  }


  return (
    <Head>
      <title>{pageTitle}</title>
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
    </Head>
  )
}

interface PageProps extends React.HTMLAttributes<HTMLDivElement> {
  symbol?: string
}

const Page: React.FC<PageProps> = ({ children, symbol, ...props }) => {
  return (
    <>
      <PageMeta symbol={symbol} />
      <StyledPage {...props}>{children}</StyledPage>
    </>
  )
}

export default Page
