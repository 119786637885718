import { useEffect } from "react";
import useTheme from "./useTheme";

export default function useDefaultTheme () {
  const { setTheme } = useTheme();

  function handleResize () {
    if ( window.matchMedia( "(max-width: 700px)" ).matches ) {
      setTheme( "dark" );
    }
  }

  useEffect( () => {
    window.addEventListener( "resize", handleResize );

    return () => {
      window.removeEventListener( "resize", handleResize );
    };


  }, [] );
}